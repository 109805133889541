import React from 'react'
import styled from 'styled-components'
import { withPrefix } from 'gatsby'
import loadable from '@loadable/component'
import Hero from '../components/hero'
import Wrapper from '../components/wrapper'
import SEO from '../components/SEO'

const Layout = loadable(() => import('../components/layout'))

const MainTitle = styled.h1`
  line-height: 1.5;
  text-align: center;
  font-size: 3rem;
`

const Text = styled.p`
  text-align: center;
`

const NotFoundPage = ({ location }) => (
  <Layout location={location} noCover={true}>
    <SEO title="Page Not Found" />
    <Hero heroImg={withPrefix('/images/404.jpg')} title="404" />
    <Wrapper>
      <MainTitle>Page Not Found</MainTitle>
      <Text>
        Looks like you've followed a broken link or entered a URL that doesn't
        exist on this site.
      </Text>

      <Text>
        I used to have a blog on this site that I've since removed, so maybe
        that's why.
      </Text>

      <Text>
        Sorry. I know cool URLs don't change; I guess I'm only medium-cool.
      </Text>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
